import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../layout'
import SEO from '../utils/seo'

import BannerDivider from '../components/bannerDivider'
import { CardService } from '../components/cards'
import ServiceStyle from '../assets/styles/pages/services'

const ServicesPage = () => {
    const { services } = useStaticQuery(graphql`
        query {
            services: allStrapiServicos {
                nodes {
                    id
                    title
                    description
                    text_button
                    form_link
                    image {
                        publicURL
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Serviços" />
            <BannerDivider title="Serviços" subtitle="Comprometimento com o cliente" />
            <ServiceStyle className="col-10">
                <div className="wrapperCardsService">
                    {services?.nodes.length &&
                        services?.nodes.map((service, index) => {
                            return (
                                <div key={index} className="cardService">
                                    <CardService
                                        img={service.image.publicURL}
                                        title={service.title}
                                        description={service.description}
                                        linkForm={service.form_link}
                                        textButton={service.text_button}
                                    />
                                </div>
                            )
                        })}
                </div>
            </ServiceStyle>
        </Layout>
    )
}

export default ServicesPage
