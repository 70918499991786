import styled from 'styled-components'

export default styled.div`
    .wrapperCardsService {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        margin: 30px 0;
    }
`
