import styled from 'styled-components'

export const CardSimpleStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    height: auto;
    margin: 5px;

    width: 22vw;
    min-width: 300px;
    max-width: 405px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0.25rem;

    .cardBody {
        padding: 16px 1vw 32px;
    }

    .itemImg {
        .imageCard {
            height: 190px;
            width: 100%;
            border-radius: 3px 3px 0px 0px;
        }
    }
    .itemContent {
        min-height: 115px;

        p.description-text {
            color: #888484;
        }
        .messageCard {
            text-align: justify;
        }
    }
`

export const CardExplanatoryStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #ececec;

    border-radius: 14px;
    margin: 0 10px 20px;
    height: 365px;
    width: 300px;

    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%);

    .imgCardExplanatory {
        align-self: center;
        padding: 20px;
        img {
            max-width: 100px;
        }
    }

    .dividerCardExplanatory {
        width: 250px;
    }

    .textCardExplanatory {
        padding: 20px;
        line-height: 20px;

        h2 {
            text-align: center;
            letter-spacing: 2px;
        }

        p {
            font-size: 15px;
        }
    }
`

export const CardStaffStyles = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: auto;
    max-width: 280px;
    height: 450px;
    margin-bottom: 40px;
    margin-right: 2px;

    background: #f8f8f8;

    border-radius: 4px;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 0px 6px 0 rgb(0 0 0 / 12%);

    :hover {
        background: #dd4937;
        color: #f8f8f8;
    }

    .header-staff-card {
        flex-grow: 1;
        flex-basis: 100%;
        max-width: 100%;

        height: 80%;

        position: relative;

        .image-staff {
            width: 100%;
            height: 100%;
        }

        .list-social-media-staff-card {
            position: absolute;
            bottom: 0px;
            left: 15px;
            padding: 0;
            opacity: 0;

            transition: all 0.5s ease;

            .item-social-media-staff {
                a {
                    height: 32px;
                    width: 32px;

                    float: left;
                    color: #fff;
                    border-radius: 50%;
                    position: relative;
                    background: #393737;
                    margin-bottom: 7px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    transition: all 0.5s ease;
                    :hover {
                        background: #1f1f1f;
                    }
                }
            }
        }

        :hover {
            .list-social-media-staff-card {
                opacity: 1;
            }
        }
    }

    .content-staff-card {
        flex-grow: 1;
        flex-basis: 100%;
        max-width: 100%;

        padding: 16px;
        text-align: center;
    }

    .wrapperContactsEmployee {
        margin-top: auto;
    }

    @media only screen and (max-width: 750px) {
        .list-social-media-staff-card {
            opacity: 1 !important;
        }
    }
`

export const CardAvailableOfficeStyles = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 0.25rem;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    width: 80%;
    margin-top: 10px;
    padding: 15px;

    background-color: #393737;

    :not(p) {
        text-align: center;
    }
`

export const ContentCatalogStyles = styled.div`
    height: 260px;
    width: 100%;
    min-width: 225px;
    max-width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background-image: url(${(props) => props.catalogBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;

    margin-top: 5px;
    padding: 15px;

    .contentCatalog {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 45%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 1.1rem 1.2rem;
        background-color: rgba(57, 55, 55, 0.8);

        border-bottom: 4px solid #d6373b;
        color: #f1f1f1;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;

        h1 {
            font-size: 18px;
            font-weight: 900;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
            text-align: justify;
        }
    }
    :hover,
    :focus {
        cursor: pointer;
        .contentCatalog {
            padding: 1.5rem 1.875rem;
            border-bottom: 5px solid #d22327;
        }
    }
`

export const CardServiceStyle = styled.div`
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    width: 100%;
    min-width: 320px;
    max-width: 400px;
    min-height: 450px;

    display: flex;
    align-items: center;
    flex-direction: column;

    margin-bottom: 30px;

    .header-card-service {
        background-image: url(${(props) => props.backgroundTop});
        background-size: 100% 100%;

        height: 150px;
        width: 100%;
    }

    .body-card-service {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0px 30px;
        height: 300px;

        p {
            padding: 15px 0px 25px;
            line-height: 28px;
            height: 158px;
        }
    }

    .link-card-service {
        text-decoration: none;
        color: #d12327;
        cursor: pointer;

        :hover {
            opacity: 0.8;
        }
    }

    .arrow-link-card-service {
        display: inline-block;
        width: 32px;
        height: 32px;
        border: 2px solid #d12327;
        border-radius: 50%;
        margin-right: 8px;

        :after {
            content: '';

            display: inline-block;
            margin-top: 8px;
            margin-left: 6px;

            border-top: 2px solid #d12327;
            border-right: 2px solid #d12327;

            width: 12px;
            height: 12px;

            transform: rotate(45deg);
        }
    }
`
