import React from 'react'
import Img from 'gatsby-image'

import {
    CardSimpleStyle,
    CardExplanatoryStyle,
    CardStaffStyles,
    CardAvailableOfficeStyles,
    ContentCatalogStyles,
    CardServiceStyle,
} from './styles'
import defaultImg from '../../assets/images/defaultImg.png'

import { Link } from 'gatsby'
import { FaPhoneAlt, FaLinkedinIn } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

import Divider from '../Divider'

export const CardSimple = ({ img, title, message, btnText, btnLink, state }) => {
    console.log(img, title, message, btnText, btnLink, state)
    return (
        <CardSimpleStyle>
            <div className="itemImg">
                <Img className="imageCard" fluid={img?.data} alt={`${img?.alt}`} />
            </div>

            <div className="cardBody">
                <div className="itemContent">
                    <Divider size={1} orientation="horizontal" />

                    <h3 className="">{title}</h3>

                    <Divider size={2} orientation="horizontal" />

                    <p className="description-text">{message}</p>
                </div>

                <Divider size={3} orientation="horizontal" />

                <Link className="button primary-button" to={`/${btnLink}`} state={state ?? ''}>
                    {btnText}
                </Link>
            </div>
        </CardSimpleStyle>
    )
}

export const CardExplanatory = ({ img, title, content }) => {
    return (
        <CardExplanatoryStyle>
            <div className="imgCardExplanatory">
                <img
                    src={img?.data ?? defaultImg}
                    alt={img?.alt ?? 'Imagem padrão - Minas Coding'}
                />
            </div>

            <hr className="dividerCardExplanatory" />

            <div className="textCardExplanatory">
                <h2 className="font-title mb-1">{title ?? 'Título'}</h2>
                {content ?? 'Conteudo do card'}
            </div>
        </CardExplanatoryStyle>
    )
}

export const CardEmployee = ({ img, name, office, linkedin, phoneNumber, email }) => {
    return (
        <CardStaffStyles>
            <div className="header-staff-card">
                <Img className="image-staff" fluid={img} alt={`${name} - Minas Coding`} />

                <ul className="list-social-media-staff-card">
                    {linkedin && (
                        <li className="item-social-media-staff">
                            <a
                                className="font-white"
                                href={linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedinIn />
                            </a>
                        </li>
                    )}

                    {email && (
                        <li className="item-social-media-staff">
                            <a
                                className="font-white"
                                href={`mailto:${email}?subject=Gostaria%de%entrar%em%contanto'`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <MdMail />
                            </a>
                        </li>
                    )}

                    {phoneNumber && (
                        <li className="item-social-media-staff">
                            <a
                                className="font-white"
                                href={`tel:${phoneNumber}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaPhoneAlt />
                            </a>
                        </li>
                    )}
                </ul>
            </div>

            <div className="content-staff-card">
                <h3 className="font-subtitle">{name ?? 'Nome da pessoa'}</h3>
                <p>{office ?? 'Cargo da pessoa'}</p>
            </div>
        </CardStaffStyles>
    )
}

export const CardAvailableOffice = ({ title, description, linkButton }) => {
    return (
        <CardAvailableOfficeStyles className="font-white">
            <div className="contentAvailableOffice">
                <h2 className="titleAvailableOffice mb-2">{title ?? 'Título da Vaga'}</h2>

                <p className="font-paragraph">{description ?? 'Descrição da vaga'}</p>
            </div>

            <div className="contactAvailableOffice mt-2">
                <h3 className="font-subtitle mb-2">A vaga combina com você?</h3>
                <a
                    href={linkButton ?? 'https://www.linkedin.com/company/minas-coding/about/'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="button white-button btnAnimatedLink">
                        Descubra mais aqui!
                    </button>
                </a>
            </div>
        </CardAvailableOfficeStyles>
    )
}

export const CardCatalog = ({ title, description, imageCard }) => {
    return (
        <Link to={`/produto/${title.toLowerCase()}`} className="linkWithoutEffect">
            <ContentCatalogStyles catalogBackground={`${imageCard ?? null}`}>
                <div className="contentCatalog">
                    <h1>{title.toUpperCase()}</h1>

                    <p>{description}</p>
                </div>
            </ContentCatalogStyles>
        </Link>
    )
}

export const CardService = ({ img, title, textButton, description, linkForm }) => (
    <CardServiceStyle backgroundTop={img}>
        <div className="header-card-service" />

        <div className="body-card-service">
            <h1 className="font-subtitle font-center">{title}</h1>

            <p>{description}</p>

            {linkForm && (
                <a
                    class="link-card-service"
                    href={linkForm}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span class="arrow-link-card-service"></span>
                    {textButton || 'Veja mais sobre aqui'}
                </a>
            )}
        </div>
    </CardServiceStyle>
)
